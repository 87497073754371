<script lang=ts>
	import { onMount } from 'svelte';

  let titleElement : HTMLElement ;

  onMount(() => {
    const ztext = new window.zDraw(titleElement , {
      depth : "50px" ,
      layers : 10 ,
    }) ;
  })

</script>

<svelte:head>
	<title>Home</title>
	<meta name="description" content="Svelte demo app" />
</svelte:head>

<section>
	<h1 bind:this={titleElement}>
    MIOUs
	</h1>

</section>

<style lang="scss">
	section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 0.6;
	}

	h1 {
    font-size : 20em ;
    // @include text3d(15 , blue) ;
    color : black ;

  }

  :global(h1 .z-text) {
    transform: rotateX(10deg) rotateY(-10deg);
    transition : transform 5s ;
  }

  :global(h1:hover .z-text) {
    transform : rotateX(-10deg) rotateY(10deg) ;
  }

  :global(h1 .z-layer:nth-child(n+2)) {
    color:blue ;
  }

</style>
